/**
 * Stuff below adds CSRF and property-key to all XHR, fetch() and form submit requests
 * Super important for this script to be run before any requests are sent in the page.
 */

const scriptEl = document.getElementById('supercommon-js');
const csrfParam = document.querySelector('meta[name="csrf-param"]').content;
const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

/**
 * @param {string} url
 * @returns {boolean}
 */
function isUrlCrossOrigin(url) {
    // non-cross origin is considered when url is relative (e.g. /blabla) or absolute (but same subdomain).
    return url.indexOf('://') !== -1 && url.indexOf(`://${window.location.hostname}`) === -1;
}

/**
 * @param {HTMLFormElement} formEl
 * @param {SubmitEvent} [event]
 */
function addInputsToForm(formEl, event) {
    const submitter = event?.submitter ? event.submitter.closest('[formmethod]') : undefined;
    const method = (submitter?.formmethod || formEl.method || 'GET').toUpperCase();

    if (method === 'GET') {
        // Having stuff in url query params causes issues when switching property.
        // (there is a redirect on property switch)
        // also url becomes ugly af
        return;
    }

    if (scriptEl.dataset.property_key && !formEl.querySelector(`input[name="${scriptEl.dataset.property_param}"]`)) {
        const inputEl = document.createElement('input');
        inputEl.type = 'hidden';
        inputEl.name = scriptEl.dataset.property_param;
        inputEl.value = scriptEl.dataset.property_key;
        formEl.appendChild(inputEl);
    }

    if (!formEl.querySelector(`input[name="${csrfParam}"]`)) {
        const inputEl = document.createElement('input');
        inputEl.type = 'hidden';
        inputEl.name = csrfParam;
        inputEl.value = csrfToken;
        formEl.appendChild(inputEl);
    }
}

// Handle cases when form is submitted through JS
// Example:
/*
    document.querySelector('body').insertAdjacentHTML('afterbegin', '<form id="ukraine" method="POST" action="/logout" style="display:none"></form>');
    document.getElementById('ukraine').submit();
 */
const originalSubmit = HTMLFormElement.prototype.submit;
HTMLFormElement.prototype.submit = function () {
    /** @type {HTMLFormElement} */
    const formEl = this;
    addInputsToForm(formEl);

    originalSubmit.apply(this, arguments);
};

// Handle cases when a dynamically created form is submitted through user action (button click)
// e.g. when a form is not part of the original html, but is added at runtime through JS
// Example:
/*
    document.querySelector('body').insertAdjacentHTML('afterbegin', `
        <form method="POST" action="/logout">
            <button type="submit">CLICK ME!</button>
        </form>
    `);
    *please click the button*

    TODO: There is 1 case that is still unresolved
          MutationObserver is asynchronous, so it receives the node added event after click() is already executed
    document.querySelector('body').insertAdjacentHTML('afterbegin', `
        <form method="POST" action="/logout">
            <button id="russia" type="submit">CLICK ME!</button>
        </form>
    `);
    document.querySelector('#russia').click(); // bypasses form.submit()
 */
const MutationObserver = window.MutationObserver || window.WebkitMutationObserver;
if (MutationObserver) {
    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type !== 'childList') {
                continue;
            }

            for (/** @type {Node} */ const addedNode of mutation.addedNodes) {
                if (addedNode.nodeType !== Node.ELEMENT_NODE || addedNode.nodeName !== 'FORM') {
                    continue;
                }

                addedNode.addEventListener('submit', (event) => {
                    /** @type {HTMLFormElement} */
                    const formEl = event.target;
                    addInputsToForm(formEl, event);
                });
            }
        }
    });
    observer.observe(document.querySelector('html'), {childList: true, subtree: true});
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap
const xhrsWithCsrfHeader = new WeakMap();
const xhrsWithPropertyKeyHeader = new WeakMap();

// Calling setRequestHeader() several times for the same key appends to existing value.
// Reason why setRequestHeader() is called multiple times is because
// we are competing with yii.js $.ajaxPrefilter that is also adding csrf header.
const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;
XMLHttpRequest.prototype.setRequestHeader = function () {
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader
    const headerName = arguments[0];

    if (headerName === scriptEl.dataset.csrf_header) {
        if (xhrsWithCsrfHeader.has(this)) {
            // csrf header already added
            return;
        }
        xhrsWithCsrfHeader.set(this, true);
    } else if (headerName === scriptEl.dataset.property_header) {
        if (xhrsWithPropertyKeyHeader.has(this)) {
            // property key header already added
            return;
        }
        xhrsWithPropertyKeyHeader.set(this, true);
    }

    originalSetRequestHeader.apply(this, arguments);
};

// Handle XHR (ajax)
// Possible scenarios:
//     req.open('pOsT', '/dashboard-settings')
//     req.open('pOsT', new URL('/dashboard-settings', 'http://3rpms.local'))
const originalXhrOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/open#parameters
    originalXhrOpen.apply(this, arguments);

    const url = arguments[1].toString(); // can be object with stringifier

    if (isUrlCrossOrigin(url)) {
        // Can only set header for same hostname, otherwise browser will throw error due to CORS
        return;
    }

    this.setRequestHeader(scriptEl.dataset.csrf_header, csrfToken);
    if (scriptEl.dataset.property_key) {
        this.setRequestHeader(scriptEl.dataset.property_header, scriptEl.dataset.property_key);
    }
};

// Handle fetch()
// Possible scenarios:
//     fetch('/dashboard-settings', {method: 'pOsT'});
//     fetch(new URL('/dashboard-settings', 'http://3rpms.local'), {method: 'pOsT'});
//     fetch(new Request('/dashboard-settings', {method: 'pOsT'}));
//     fetch(new Request('/dashboard-settings', {method: 'gEt'}), {method: 'pOsT'});
//     fetch(new Request('/dashboard-settings', {method: 'pOsT', headers: {'x-cheese': 'yes'}}), {headers: {'x-cheese': 'no'}});
const originalFetch = window.fetch;
window.fetch = function () {
    // https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
    const resource = arguments[0];
    const init = arguments[1] || {};

    let url = window.Request && resource instanceof window.Request
        ? resource.url
        // can be object with stringifier
        : resource.toString();

    if (isUrlCrossOrigin(url)) {
        // Can only set header for same hostname, otherwise browser will throw error due to CORS
        return originalFetch.apply(this, arguments);
    }

    if (window.Request && resource instanceof window.Request) {
        resource.headers.set(scriptEl.dataset.csrf_header, csrfToken);
        if (scriptEl.dataset.property_key) {
            resource.headers.set(scriptEl.dataset.property_header, scriptEl.dataset.property_key);
        }
    }

    // Can be also called like this:
    //   fetch(new Request('/', {headers: {'x-cheese': 'yes'}}), {headers: {}})
    //     headers in Request() are overwritten by headers in init
    if (!(window.Request && resource instanceof window.Request) || init.headers) {
        init.headers = {
            ...init.headers,
            [scriptEl.dataset.csrf_header]: csrfToken,
        };
        if (scriptEl.dataset.property_key) {
            init.headers[scriptEl.dataset.property_header] = scriptEl.dataset.property_key;
        }
        arguments[1] = init;
    }

    return originalFetch.apply(this, arguments);
};
